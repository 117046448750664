<template>
  <div class="resourceManage container-warp">
    <el-card class="card-pd" shadow="never">
      <div slot="header">
        <el-form
          class="form-item-no-mb"
          :model="searchForm"
          ref="searchForm"
          inline
        >
          <el-form-item label="计划名称">
            <el-input
              v-model="searchForm.planName"
              placeholder="请输入计划名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="计划类型">
            <el-select v-model="searchForm.type" placeholder="请选择">
              <el-option
                v-for="item in planTypeList"
                :key="item.id"
                :label="item.categoryName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="计划日期">
            <el-date-picker
              v-model="searchForm.startTime"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              type="date"
              placeholder="请选择"
            >
            </el-date-picker>
          </el-form-item>
          <el-button @click="_reset">重置</el-button>
          <el-button type="primary" @click="_search">查询</el-button>
        </el-form>
      </div>
      <!-- <div class="card-box">
        <div class="state-box">
          <img src="@/assets/img/icon_plan.png" alt="" />
          <div class="state-box-right">
            <div><span>未发布</span><span>{{ notIssueTotal }}个</span></div>
            <div><span>进行中</span><span>{{ underwayToatl }}个</span></div>
            <div><span>已结束</span><span>{{ finishedToatl }}个</span></div>
          </div>
        </div>

      </div> -->
      <el-button type="primary" @click="_addPlan"><i class="el-icon-plus"></i><span>新增计划</span></el-button>
    </el-card>

    <div class="plan-list-box">
      <el-tabs v-model="searchForm.status" @tab-click="_tabClick" :style="{'--font-Color' : fontColor}">
        <el-tab-pane
          v-for="(item, index) in tabList"
          :key="index"
          :label="item.title"
          :name="item.name"
        >
          <div class="edit-info-box">
            <div class="empty-box" v-if="studyPlanList.length === 0">
              <PlaceholderMap text="暂无学习计划噢~" />
            </div>
            <div class="plan-item-box" v-for="(item,index) in studyPlanList" :key="index" @click.stop="_viewDetails(item,'detail',1)">
              <div class="plan-item-title">
                <div class="plan-item-title-left">
                  <div class="plan-item-title-mc">{{ item.planName || '--'}}</div>
                  <div class="plan-item-title-bq" v-if="item.typeValue">{{ item.typeValue || '--'}}</div>
                </div>
                <div class="plan-item-title-right">
                  <el-button type="text" v-if="searchForm.status==='0'" @click.stop="_viewDetails(item,'class',2)">课程安排</el-button>
                  <el-button type="text" v-if="searchForm.status==='0'" @click.stop="_viewDetails(item,'class',3)">受训学员</el-button>
                  <el-button type="text" v-if="searchForm.status==='2'" @click.stop="deletePlan(item)">删除计划</el-button>
                  <el-button type="text" v-if="searchForm.status!=='0'" @click.stop="seeStudyProcess(item)">查看学习进度</el-button>
                  <el-button type="text" v-if="searchForm.status==='1'" @click.stop="_adjustPlanTime(item)"
                    >调整计划时间</el-button
                  >
                </div>
              </div>
              <div class="plan-item-content">
                <div class="plan-item-content-left">
                  <div class="plan-item-content-left-ms" >{{item.description||'—'}}</div>
                  <div class="plan-item-content-left-xx">
                    <div><span style="color: #0089ff"> {{ item.employeeCount || '0'}}</span>名受训学员</div>
                    <div class="line"></div>
                    <div>计划日期：{{ item.startTime || '--'}} ~ {{ item.endTime || '--'}}</div>
                  </div>
                </div>
                <div class="plan-item-content-right">
                  <el-button  class="add-group-btn" :style="{'--lock-btn': $store.state.settings.theme}"  v-if="searchForm.status==='0'" @click.stop="mastBeginClass(item)">立即开始</el-button>
                  <el-button type="primary" plain v-if="searchForm.status!=='0'" @click.stop="_viewDetails(item,'detail',1)"
                    >查看详情</el-button
                  >
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>

      <div style="display: flex; justify-content: flex-end; margin-top: 0px">
        <el-pagination
            class="fx-row-end pt16"
            @size-change="_handleSizeChange"
            @current-change="_handleCurrentChange"
            :current-page="searchForm.page"
            :page-sizes="[10, 20, 30, 40, 50]"
            :page-size="searchForm.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="searchForm.total"
          >
          </el-pagination>
      </div>
    </div>

    <el-dialog
      class="plan-time-box"
      title="调整计划时间"
      :visible.sync="planTimeDialog"
      :close-on-click-modal="false"
    >
      <el-form ref="planForm" :model="planTimeForm" :rules="planRules">
        <el-form-item label="开始时间"  prop="startTime">
          <el-date-picker
            v-model="planTimeForm.startTime"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            type="date"
            placeholder="请选择"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间" prop="endTime">
          <el-date-picker
            v-model="planTimeForm.endTime"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            type="date"
            placeholder="请选择"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="planTimeDialog = false">取 消</el-button>
        <el-button type="primary" @click="planTimeDefine"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getStudyPlanListApi,
  getStatusTotalApi,
  updateStatusApi,
  removeTrStudyPlanApi,
  editPlanDetailApi
} from '@/api/study.js'
import { getFindCustomizeTypeApi } from '@/api/settings'
import PlaceholderMap from '@/components/placeholderMap/index.vue'
export default {
  components: {
    PlaceholderMap
  },
  data () {
    return {
      fontColor: this.$store.state.settings.theme,
      searchForm: {
        planName: '', // 计划名称
        type: '', // 计划类型
        startTime: '', // 计划日期
        status: '0', // 状态
        page: 1,
        pageSize: 10
      },
      planTypeList: [],
      tabList: [],
      planTimeDialog: false,
      planTimeForm: {
        startTime: '',
        endTime: ''
      },
      studyPlanList: [], // 学习计划列表
      studyPlanTotal: null, // 学习计划总数
      notIssueTotal: 0, // 未发布总数
      underwayToatl: 0, // 进行中总数
      finishedToatl: 0, // 已结束总数
      currentItem: {},
      planRules: {
        startTime: [
          { required: true, message: '请选择开始时间', trigger: 'blur' }
        ],
        endTime: [
          { required: true, message: '请选择结束时间', trigger: 'change' }
        ]
      }
    }
  },
  created () {
    this.fontColor = this.$store.state.settings.theme
    this._getPlanTypeList()
    this._getStatusTotal()
    this._getStudyPlanList()
  },
  methods: {
    // 删除计划
    deletePlan (item) {
      this.$confirm('确定要删除吗？', '提示', {
        type: 'warning'
      })
        .then(() => {
          removeTrStudyPlanApi([item.id]).then((res) => {
            this.$message.success('删除成功')
            this.$router.push({
              path: '/studyPlan'
            })
            this._getStatusTotal()
            this._getStudyPlanList()
          })
        })
        .catch(() => {})
    },
    // 获取学习计划列表
    _getStudyPlanList () {
      getStudyPlanListApi(this.searchForm).then((res) => {
        if (res.code === 200) {
          this.studyPlanList = res.object.results
          this.searchForm.total = res.object.total
        }
      })
    },
    // 获取计划类型数据
    _getPlanTypeList () {
      getFindCustomizeTypeApi({
        type: 1
      }).then(res => {
        if (res.code === 200) {
          this.planTypeList = res.data.planType
        }
      })
    },
    _getStatusTotal () {
      getStatusTotalApi({
        planName: this.searchForm.planName,
        type: this.searchForm.type,
        startTime: this.searchForm.startTime
      }).then(res => {
        if (res.code === 200) {
          res.data.forEach(item => {
            if (item.status === 0) {
              this.notIssueTotal = item.total
            } else if (item.status === 1) {
              this.underwayToatl = item.total
            } else if (item.status === 2) {
              this.finishedToatl = item.total
            }
          })
          this.tabList = [
            {
              title: `未发布(${this.notIssueTotal})`,
              name: '0'
            },
            {
              title: `进行中(${this.underwayToatl})`,
              name: '1'
            },
            {
              title: `已结束(${this.finishedToatl})`,
              name: '2'
            }
          ]
        }
      })
    },
    // 立即开课
    mastBeginClass (item) {
      updateStatusApi({
        status: 1,
        id: item.id
      }).then(res => {
        if (res.code === 200) {
          this._getStudyPlanList()
          this._getStatusTotal()
        }
      })
    },
    // 重置
    _reset () {
      this.searchForm.planName = ''
      this.searchForm.type = ''
      this.searchForm.startTime = ''
      this.searchForm.page = 1
      this.searchForm.pageSize = 10
      this._getStatusTotal()
      this._getStudyPlanList()
    },
    // 查询
    _search () {
      this._getStatusTotal()
      this._getStudyPlanList()
    },
    _handleSizeChange (val) {
      this.searchForm.pageSize = val
      this._search()
    },
    _handleCurrentChange (val) {
      this.searchForm.page = val
      this._search()
    },
    // 切换tab
    _tabClick (tab) {
      this.searchForm.status = tab.name
      this.searchForm.page = 1
      this._getStudyPlanList()
    },
    // 调整计划时间
    _adjustPlanTime (item) {
      this.planTimeDialog = true
      this.planTimeForm = JSON.parse(JSON.stringify(item))
      this.currentItem = item
    },
    planTimeDefine () {
      // 调整计划时间确认
      this.$refs.planForm.validate((valid) => {
        if (valid) {
          if (this.planTimeForm.startTime > this.planTimeForm.endTime) {
            this.$message.error('开始时间不能大于结束时间！')
            return
          }
          editPlanDetailApi({ ...this.currentItem, ...this.planTimeForm }).then(res => {
            if (res.code === 200) {
              this.planTimeDialog = false
              this._getStudyPlanList()
              this.$message.success('操作成功！')
            }
          })
        }
      })
    },
    seeStudyProcess (item) {
      // 查看学习进度
      this.$router.push({
        path: '/learningProgress',
        query: {
          studyPlanName: item.planName
        }
      })
    },
    // 新增计划
    _addPlan () {
      this.$router.push({
        path: '/addPlan'
      })
    },
    // 查看详情
    _viewDetails (item, text, status) {
      this.$router.push({
        path: `/editPlan?id=${item.id}&type=${text}&status=${status}`
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.card-pd {
  /deep/.el-card__header {
    padding: 16px 24px;
  }
  /deep/ .el-card__body {
    padding: 16px 24px;
  }
}
/deep/.el-tabs__item.is-active{
    color:var(--font-Color)!important;
  }
  /deep/.el-tabs__item{
    color: #737373!important;
    padding: 0 16px!important;
  }
  /deep/.el-tabs__header{
    margin: 0 0 24px;
  }
.form-item-no-mb {
  /deep/.el-form-item {
  margin-bottom: 16px !important;
  margin-right: 32px !important;
}
  /deep/.el-form-item__label {
    padding-right: 16px;
    color: rgba(0, 0, 0, 0.9);
  }
}
/deep/.el-button {
  // margin-left: 16px !important;
  font-size: 14px;
  height: 32px;
}
/deep/.el-button--default {
  color: rgba(0, 0, 0, 0.9);
}
.card-box {
  display: flex;
  align-items: center;
}
.state-box {
  display: flex;
  padding: 9px 54px 9px 24px;
  margin-right: 16px;
  width: 366px;
  height: 70px;
  background: linear-gradient(to right bottom, #0089ff, #34a6f7);
  border-radius: 8px;
  box-sizing: border-box;
  img {
    margin-right: 20px;
    margin-top: 5px;
    width: 48px;
    height: 48px;
  }
  .state-box-right {
    flex: 1;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #ffffff;
    div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      span {
        &:last-child {
          margin-top: 8px;
        }
      }
    }
  }
}
.edit-info-box{
  height:  calc(100vh - 500px);
  overflow: auto;
}
.plan-list-box {
  margin-top: 16px;
  padding: 0 24px 14px 24px;
  background-color: #ffffff;
  border-radius: 4px;
  // height: 650px;
  /deep/.el-tabs__nav {
    height: 55px;
  }
  /deep/.el-tabs__item {
    line-height: 55px;
  }
}

.plan-item-box {
  padding: 16px 24px;
  margin-top: 24px;
  background: linear-gradient(to bottom, #f0f8ff, #ffffff);
  border: 1px solid #e7f4ff;
  border-radius: 8px;
  &:first-child {
    margin-top: 0;
  }
  .plan-item-title {
    display: flex;
    justify-content: space-between;
    .plan-item-title-left {
      display: flex;
      align-items: center;
      .plan-item-title-mc {
        font-size: 16px;
        font-weight: 600;
        color: #333333;
      }
      .plan-item-title-bq {
        padding: 3px 12px;
        margin-left: 16px;
        font-size: 12px;
        color: #ffffff;
        border-radius: 8px 0px 8px 0px;
        background: linear-gradient(to right, #31adf6, #5096ff);
      }
    }
  }
  .plan-item-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;
    .plan-item-content-left {
      .plan-item-content-left-ms {
        margin-bottom: 12px;
        width: 450px;
        font-size: 14px;
        color: #666666;
      }
      .plan-item-content-left-xx {
        display: flex;
        align-items: center;
        font-size: 12px;
        .line {
          margin: 0 12px;
          width: 1px;
          height: 16px;
          background-color: #dcdcdc;
        }
      }
    }
  }
}
.plan-time-box {
  /deep/.el-dialog {
    width: 400px;
    height: 246px;
  }
  /deep/.el-dialog__title {
    font-size: 16px;
    color: hsla(0, 0%, 0%, 0.9);
    font-weight: 500;
  }
  /deep/.el-dialog__body {
    padding: 20px 30px 14px 24px;
  }
}
</style>
